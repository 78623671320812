<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card class="v-card-bottom-30">
      <v-card-title>
        {{use_language.stock}}
        <v-icon>mdi-chevron-right</v-icon>
        {{use_language.product_stock}}
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- รายละเอียดสินค้าจัดเซ็ต -->
    <v-dialog v-model="dialogDetail" max-width="60%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon><span class="ml-3">{{use_language.info_product}}</span>
          <v-spacer/>
          <v-btn color="primary" class="pa-2 mr-2" @click="UpdateStock()"><v-icon size="16" class="pr-1">fa-edit</v-icon>{{use_language.edit}}</v-btn>
          <v-btn icon @click="dialogDetail=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto ">

          <!-- PC hidden-xs-only -->
          <v-data-table
            :headers="headerDetail"
            :items="datasetDetail"
            :items-per-page="9999999999"
            hide-default-footer
            class="packhai-border-table hidden-xs-only"
          >
            <template v-slot:item.no="{ item }">
              {{ formatMoney(datasetDetail.length - datasetDetail.indexOf(item)) }}
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize mt-1">
              </a>
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
              </span>
            </template>
            <template v-slot:item.quantity="{ item }">
              {{ formatMoney(item.quantity) }}
            </template>
          </v-data-table>

          <!-- Mobile hidden-sm-and-up -->
          <v-data-iterator
            :items="datasetDetail"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
            class="hidden-sm-and-up"
          >
            <template v-slot:default="props">
              <v-row class="ma-0">
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12">{{use_language.no_picture}} </span>
                        </a>
                        <div class="v-list-item__content pa-2 pl-0" >

                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                                {{use_language.name}}
                              </div>
                              <div style="line-height: 1rem !important;">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="cut-text">
                                      <span v-if=" item.name != null">: {{ item.name }}</span>
                                    </div>
                                  </template>
                                  <span v-if=" item.name != null">{{ item.name }}</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  {{use_language.type}}
                                </div>
                                <div class="cut-text">
                                  : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.number_product}}
                                </div>
                                <div class="cut-text">
                                  : {{ item.productCode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.sku}}
                                </div>
                                <div class="cut-text">
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.barcode}}
                                </div>
                                <div class="cut-text">
                                  : {{ item.barcode }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.quantity}}
                                </div>
                                <div>
                                  : {{ formatMoney(item.quantity) }}
                                </div>

                              </div>

                            </div>
                          </div>

                        </div>
                      </div>


                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>

        </v-card-text>
      </v-card>
    </v-dialog>
 

    <!-- ค้นหาสินค้า -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <div class="py-1">
          <v-icon size="22" color="orange darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3">{{use_language.product_stock}}</span>
        </div>
        <v-spacer/>
        <div>
          <v-btn :elevation="1" color="primary" class="px-2"  @click="navigateExcel()">
            <v-icon size="13" class="ml-1"  left>fa-plus</v-icon>
          เพิ่มรายการใหม่ excel
          </v-btn>
          <vue-excel-xlsx
              id="GetExcel"
              v-show="false"
              :data="datasetExcel"
              :columns="headerExcel"
              :filename="'mystock'"
              :sheetname="'shee1'"
              >
          </vue-excel-xlsx>
          <v-btn :elevation="1" outlined color="success" class="px-2 ml-5" @click="ExportExcel(search)">
            <v-icon size="18" class="ml-1" left>fa-download</v-icon>
          {{use_language.export_excel}}
            <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-row>
          <v-col cols="12" lg="6" class="pa-0">
            <v-row>
              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> {{use_language.treasury}} : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-select
                  v-model="warehouse_selected"
                  :items="warehouse_data"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>


              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> {{use_language.name_product}} : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.name"
                  @change="value => search.name = Trim_value(value)"
                  @keyup.enter="SearchStock(true)"
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>

              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> {{use_language.number_product}} : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.productCode"
                  @change="value => search.productCode = Trim_value(value)"
                  @keyup.enter="SearchStock(true)"
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                   placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>

              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> {{use_language.barcode}} : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.barcode"
                  @change="value => search.barcode = Trim_value(value)"
                  @keyup.enter="SearchStock(true)"
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                  placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>

              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> {{use_language.sku}} : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.sku"
                  @change="value => search.sku = Trim_value(value)"
                  @keyup.enter="SearchStock(true)"
                  ref="refaddFCName"
                  outlined
                  dense hide-details
                  autocomplete="off"
                  placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="12" lg="6" class="pa-0">
            <v-row>

              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14">Brand : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.brand"
                  @change="value => search.brand = Trim_value(value)"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>


              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> {{use_language.show_products}} : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-select
                  v-model="search.showProduct"
                  :items="search.showProductList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> {{use_language.open_sale}} : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-select
                  v-model="search.isActive"
                  :items="search.isActiveList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12"  lg="3" class="pt-1 pb-2">
                <h3 class="fn-14"> ประเภทสินค้า : </h3>
              </v-col>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-select
                  v-model="search.showSetProduct"
                  :items="search.showSetProducList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12"  lg="3" class="pt-1 pb-2"/>
              <v-col cols="12"  lg="8" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2" dark @click="SearchStock(true)">
                  <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                  {{use_language.search}}
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <!-- ข้อมูลทั้งหมด -->
    <v-row class="mb-2">
      <v-col cols="6" class="pa-0">
        <span class="head-doc"> {{use_language.product}} </span> {{use_language.all_}} {{ formatMoney(total) }} {{use_language.row}}
      </v-col>
      <v-col cols="6" class="pa-0" align="right">

        <v-btn v-if="IsHeaderDataNotTheSame" outlined color="primary" @click="update_header_table()">
          <!-- <v-icon left>mdi-table-arrow-left </v-icon> -->
          {{use_language.save_change_log}}
        </v-btn>
         <v-btn   color="" class="pa-2 ml-2 mt-1"  icon @click="changeFormat_DataTabel()">
              <v-icon v-if="!formatDataTabel" size="16" class="pr-1">fa-table</v-icon>
              <v-icon v-else size="16" class="pr-1">fa-list</v-icon>

            </v-btn>
      </v-col>
    </v-row>

    <!-- แสดงตาราง -->
    <v-card elevation="1">
      <v-card-text class="pa-0">
        <v-card>
          <!-- <v-tabs v-model="tab" dark background-color="teal darken-3" show-arrows>
            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
            <v-tab v-for="i in tab_list" :key="i.id" @click="ChangTab(i.id,i.warehouseId)"> {{ i.name+" ("+formatMoney(i.total)+")" }} </v-tab>
          </v-tabs> -->

        </v-card>
         
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="showHeaders"
              :items="dataset"
              :items-per-page="9999999999"
              item-key="no"
              hide-default-footer
              :loading="tableloading"
              class="packhai-border-table hidden-xs-only"
              v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
              :key="anIncreasingNumber" >
            >
              <template v-for="(header, index) in headers" v-slot:[`header.${header.value}`]="{ header }">
                <v-tooltip :key="index" v-slot:activator="{ on }">
                  <span v-if="use_language[header.labal]!=null && use_language[header.labal]!=''"> {{use_language[header.labal]}}    </span>
                  <span v-else> {{ header.labal }}  </span>
                  <!-- <span> {{ header.text }} </span> -->
                  <span v-if="header.value=='name' || header.value=='prop1' || header.value=='unitPrice' || header.value=='productCode' || header.value=='cfCode'|| header.value=='sku' || header.value=='barcode' || header.value=='quantity'|| header.value=='quantityBuffer' || header.value=='quantityWaiting' || header.value=='quantityWaitImport' || header.value=='quantityAvailable'">
                    <v-icon v-if="header.icon==0" @click="OrderByCollum(index)" size="16" class="icon-white">
                      mdi-arrow-up
                    </v-icon>
                    <v-icon v-else-if="header.icon==1" @click="OrderByCollum(index)" size="16" >
                      mdi-arrow-up
                    </v-icon>
                    <v-icon v-else-if="header.icon==2" @click="OrderByCollum(index)" size="16" >
                      mdi-arrow-down
                    </v-icon>
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.no="{ item }">
                {{ formatMoney((total-dataset.indexOf(item))-offsets) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
                <a v-else>
                      <img src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/WhiteImage.JPG" class="picsize mt-1">
                </a>
              </template>
               <template v-slot:item.name="{ item }">
                <v-row>
                  <v-col cols="12" align="left" class="pa-0">
                    <span class="fn-13" style="font-weight: bold">
                      <a @click="gotoStock_InFo(item)">
                  {{item.name}}
                </a>
                </span>
                  </v-col>
                </v-row>

                <v-row v-if="!formatDataTabel">
                  <v-col cols="12" align="left" class="pa-0">
                    <span class="fn-13">{{!item.barcode ? "" : use_language.barcode +" : "}} {{ item.barcode }}  </span>
                  </v-col>

                </v-row>

                <v-row v-if="!formatDataTabel">
                  <v-col cols="12" align="left" class="pa-0">
                    <span class="fn-13">
                       {{!item.productCode ? "" :  use_language.number_product + " : "}}  {{ item.productCode }}
                       {{!item.prop1Description && !item.prop2Description ? "" : use_language.type + "  : "}}  {{ item.prop1Description }}  {{item.prop2Description }}
                    </span>
                  </v-col>

                </v-row>
                <v-row v-if="!formatDataTabel && item.brand!=null && item.brand!=''">
                  <v-col cols="12" align="left" class="pa-0">
                    <span class="fn-13">
                       {{  "Brand : " + item.brand }}
                      
                    </span>
                  </v-col>

                </v-row>
                <v-row v-if="item.isProductSet">
                  <v-col cols="12" align="left" class="pa-0">
                    <span  class="fn-13" >
                      <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                      {{use_language.set_products}}
                    </span>
                  </v-col>
                </v-row>
              </template>
           
              <template v-slot:item.prop1="{ item }">
                <span class="fn-13">
                  {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                </span>
              </template>
              <template v-slot:item.unitPrice="{ item }">
                {{formatMoney(item.unitPrice)}}
              </template>
              <template v-slot:item.quantity="{ item }">
                <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                  <b>
                    {{formatMoney(item.quantity)}}
                  </b>
                </span>
                <span v-else >
                  {{formatMoney(item.quantity)}}
                </span>
              </template>
              <template v-slot:item.quantityBuffer="{ item }">
                <span >
                  {{formatMoney(item.quantityBuffer)}}
                </span>
              </template>
              <template v-slot:item.quantityWaiting="{ item }">
                
                <span >
                  {{formatMoney(item.quantityWaiting)}}
                </span>
              </template>
              <template v-slot:item.quantityWaitImport="{ item }">
                 
                <span >
                  {{formatMoney(item.quantityWaitImport)}}
                </span>
              </template>
              <template v-slot:item.quantityAvailable="{ item }">
                
                <span >
                  {{formatMoney(item.quantityAvailable)}}
                </span>
              </template>
              <template v-slot:item.isActive="{ item }">
                <v-row justify="center">
                  <a v-if="PermisstionManageStock ==2" @click="UpdateIsActive(dataset.indexOf(item),item)">
                    <v-switch v-model="item.isActive" color="red" hide-details ></v-switch>
                  </a>
                  <a v-else>
                    <v-switch v-model="item.isActive" readonly color="red" hide-details ></v-switch>
                  </a>
                </v-row>
              </template>
              <template v-slot:item.isUpdateStockMarketplace_Item="{ item }">
                <v-row justify="center">
                  <a v-if="PermisstionManageStock==2" @click="UpdateMarketplace_Item(dataset.indexOf(item),item)">
                    <v-switch v-model="item.isUpdateStockMarketplace_Item" label="" color="success" ></v-switch>
                  </a>
                  <a v-else>
                    <v-switch v-model="item.isUpdateStockMarketplace_Item" readonly label="" color="success" ></v-switch>
                  </a>
                </v-row>
              </template>
           
              <template v-slot:item.delete="{ item }">
                <a @click="DeleteStock(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
              </template>
              <template v-slot:item.editstock="{ item }">
                <a v-if="PermisstionManageStock ==2" @click="OpenDialogEditStock(item)"><v-icon size="16" color="red darken-3">fa-edit</v-icon></a>
              </template>
            </v-data-table>

            <!-- Mobile hidden-sm-and-up -->
            <v-data-iterator
              :items="dataset"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer
              class="hidden-sm-and-up"
            >
                 <template v-slot:default="props">
        <v-row class="ma-2">
        <v-col v-for="item in props.items" :key="item.id" cols="12" class="">
        <v-card>
        <v-row >
        <v-col cols="4" class="text-right" >
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                         <img :src="item.photoLink" class="picsize">
                        </a>
                         <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> {{use_language.no_picture}} </span>
                        </a>
        </v-col>

        <v-col cols="8"  class="pl-0">
          <v-row class="flex-column ma-0 "  >

         <v-col class="px-0 py-1" >
              <div style="line-height: 1rem !important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <b>
                      <div v-bind="attrs" v-on="on" >
                        <span v-if="item.name != null"> {{ item.name }}</span>
                       </div>
                    </b>
                   </template>
                     <span v-if=" item.name != null">   {{ item.name }}</span>
                 </v-tooltip>
               </div>
         </v-col>

         <v-col class="px-0 py-0" v-if="item.sku" >
            <span v-if="item.sku" class="color-gray-12" >{{use_language.sku}}  : {{ item.sku }}</span>
         </v-col>

         <v-col class="px-0 py-0" v-if="item.prop1Description || item.prop2Description" >
            <span v-if="item.prop1Description || item.prop2Description"  class="color-gray-12">{{use_language.type}} :
              {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "")
             + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
            </span>
         </v-col>

        <v-col class="px-0  py-0" v-if="item.productCode" >
           <span  v-if="item.productCode " class="color-gray-12">{{use_language.number_product}} : {{ item.productCode }}</span>
        </v-col>

        <v-col class="px-0  py-0" v-if="item.barcode" >
           <span v-if="item.barcode"  class="color-gray-12" > {{use_language.barcode}}  : {{ item.barcode }}</span>
        </v-col>

         <v-col class="px-0  py-0" v-if="item.cfCode ">
           <span class="color-gray-12">{{use_language.code_cf}} : {{ item.cfCode }}</span>
        </v-col>

        <v-col class="px-0  py-0"    >
          <span  v-if="item.isActive">
             <span class="color-gray-12"> {{use_language.open_sale}} </span>
            <v-icon size="18" color="green" disabled >mdi-check-bold</v-icon>
          </span>

           <span  v-else>
             <span class="color-gray-12"> {{use_language.non_open_sale}} </span>
            <v-icon size="18" color="red" disabled >mdi-eye-off-outline</v-icon>
           </span>


        </v-col>
       
        <v-col class="px-0  py-0" v-if="item.isProductSet" >
          <span  class="fn-13" >
            <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
            {{use_language.set_products}}
          </span>
        </v-col>
                
     </v-row>
        </v-col>
           </v-row>
           <v-row class="packhai-showproduct-3 pa-2">
                        <v-col cols="3" class="text-center py-0">
                          <b>{{use_language.balance}}</b>
                        </v-col>
                        <v-col cols="3" class="text-center py-0">
                          <b>รอแพ็ค</b>
                        </v-col>
                        <v-col cols="3" class="text-center py-0">
                          <b>{{use_language.wati_import}}</b>
                        </v-col>
                        <v-col cols="3" class="text-center py-0">
                          <b>{{use_language.ready_sale}}</b>
                        </v-col>
                        <v-col cols="3" class="text-center pa-2">
                          <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                            <b>
                              {{formatMoney(item.quantity)}}
                            </b>
                          </span>
                          <span v-else >
                            {{formatMoney(item.quantity)}}
                          </span>
                        </v-col>
                        <v-col cols="3" class="text-center pa-2">
                          <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                            <b>
                              {{formatMoney(item.quantityWaiting+item.quantityBuffer)}}
                            </b>
                          </span>
                          <span v-else >
                            {{formatMoney(item.quantityWaiting+item.quantityBuffer)}}
                          </span>
                        </v-col>
                        <v-col cols="3" class="text-center pa-2">
                          <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                            <b>
                              {{formatMoney(item.quantityWaitImport)}}
                            </b>
                          </span>
                          <span v-else >
                            {{formatMoney(item.quantityWaitImport)}}
                          </span>
                        </v-col>
                        <v-col cols="3" class="text-center pa-2">
                          <span v-if="item.alertQuantity!='' && item.alertQuantity!=null && item.alertQuantity>=item.quantity" class="color-red">
                            <b>
                              {{formatMoney(item.quantityAvailable)}}
                            </b>
                          </span>
                          <span v-else >
                            {{formatMoney(item.quantityAvailable)}}
                          </span>
                        </v-col>
          </v-row>

        <v-divider/>

          <v-row>
            <v-col cols="4" class="text-center" >
                <v-btn  @click="gotoStock_InFo(item)" outlined color="orange darken-3"  width="100%" class="pa-1">
                 <v-icon size="12" >fa-edit</v-icon><span > {{use_language.info}}</span>
                 </v-btn>

            </v-col>
             <v-col cols="4" class="text-center">
                 <v-btn   @click="DeleteStock(item)" outlined color="red darken-3"  width="100%" class="pa-1">
                    <v-icon size="13" >fa-trash-alt</v-icon>
                      <span>{{use_language.delete}}</span>
                  </v-btn>
              </v-col>

              <v-col cols="4" class="text-center">
                  <v-btn  @click="OpenDialogEditStock(item)" outlined color="red darken-3"  width="100%" class="pa-1">
                    <v-icon size="13" >fa-edit</v-icon>
                      <span>แก้ไข</span>
                  </v-btn>
              </v-col>
         </v-row>

                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>

            <v-row >
      <v-col cols="2" lg="1" >
      </v-col>
      <v-col cols="8"  lg="10"  @click="ChangePage()">
        <v-pagination  v-if="pageLength>1 && dataset.length>0"
          v-model="page"
          :length="pageLength"
          :total-visible="pageTotalVisible"
          color="error"
        ></v-pagination>
      </v-col>
      <v-col cols="2"  class="text-right" lg="1" >
        <v-select  
          v-model="limits"
          label="จำนวนแสดง"
          :items="[10,20,50,100,500,1000]"
          @change="LimitChange()"
          outlined
        ></v-select>
      </v-col>
    </v-row>
      </v-card-text>
    </v-card>

    <!-- แก้ไขสต๊อกสินค้า -->
    <v-dialog v-model="editstock.dialog" scrollable max-width="45%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1">
        <v-card-title class="height-57">
          <span>แก้ไข</span>
          <v-spacer/>
          <v-btn icon @click="editstock.dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-x-auto">
          <v-form ref="editStockForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              {{use_language.saleman_name}} :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.name"
                @change="value => editstock.name = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              {{use_language.number_product}} :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.productCode"
                @change="value => editstock.productCode = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              {{use_language.sku}} :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.SKU"
                @change="value => editstock.SKU = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              {{use_language.barcode}} :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.barcode"
                @change="value => editstock.barcode = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
             ลักษณะ 1 :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.prop1Description"
                @change="value => editstock.prop1Description = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
             ลักษณะ 2 :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.prop2Description"
                @change="value => editstock.prop2Description = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              จำนวน Buffer :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.buffer"
                @change="value => editstock.buffer = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              {{use_language.selling_price}} :
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.price"
                @change="value => editstock.price = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              ต้นทุน
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.staticCostPrice"
                @change="value => editstock.staticCostPrice = Trim_value(value)"
                autocomplete="off"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pa-0">
              จำนวนคงเหลือ
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-text-field
                :value="editstock.stockquantity"
                @change="value => editstock.stockquantity = Trim_value(value)"
                autocomplete="off"
                :disabled = editstock.ispackhai
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols="12" class="pa-0" style="display:flex; flex-direction: row-reverse;">
              <v-btn :elevation="1" color="primary" class="px-2" @click="handleUpdateStock()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> {{use_language.record}} </b></span>
              </v-btn>
            </v-col>
          </v-row>

        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- เปลี่ยนหน้า -->

    <div class="v-card-bottom-30" />

  

  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import { stockService_dotnet, shopService_dotnet, ecomService_dotnet } from '@/website/global'
  import { formatMoney, formatDate_thai, format_price, isNumberWithDot, isNumberWNoDot, DateNowNotNull,
  Trim_value, Trim_rules,get_languages, ViewTypePage,UpdateViewTypePage } from '@/website/global_function'


 // สำหรับ datatable move columns start
 import Sortable from 'sortablejs';

 function watchClass(targetNode, classToWatch) {
    let lastClassState = targetNode.classList.contains(classToWatch);
    const observer = new MutationObserver((mutationsList) => {
      for (let i = 0; i < mutationsList.length; i++) {
        const mutation = mutationsList[i];
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const currentClassState = mutation.target.classList.contains(classToWatch);
          if (lastClassState !== currentClassState) {
            lastClassState = currentClassState;
            if (!currentClassState) {
              mutation.target.classList.add('sortHandle');
            }
          }
        }
      }
    });
    observer.observe(targetNode, { attributes: true });
  }
  // สำหรับ datatable move columns  end

 export default {
    components: {
      Loading
    },
    data: () => ({
      // page
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      shop:JSON.parse(localStorage.getItem("shop")),
      page_loading: true,
      loading: false,
      use_language: null,
      set_language: null,
      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',

      // Permisstion
      SeeCostPermisstion: '',
      PermisstionManageStock: '',

      // editstock
      editstock: {
        stockID:null,
        dialog:false,
        name:null,
        productCode:null,
        SKU:null,
        cfCode:null,
        barcode:null,
        price:null,
        costPrice:null,
        stockquantity:null,
        ispackhai:false,
        buffer:null,
        prop1Description:null,
        prop2Description:null,
      },

      // shopee
      shopee: {
        dialog: false,
        shopList: [],
        shop: {"id": 0, "name": "--เลือกร้านค้า--"},
        warehouseList: [],
        warehouse: [],
        dataset: [],
        header: [
          { text: 'No',align: 'center',sortable: false,value: 'No', width: '60px' },
          { text: 'รูป',align: 'center',sortable: false,value: 'PhotoLink', width: '100px'},
          { text: 'ชื่อ',align: 'left',sortable: false,value: 'Name'},
          { text: 'ลักษณะ',align: 'left',sortable: false,value: 'props'},
          { text: 'SKU',align: 'center',sortable: false,value: 'SKU'},
          { text: 'จำนวน',align: 'right',sortable: false,value: 'Quantity', width: '100px'},
          { text: 'ราคาขาย',align: 'right',sortable: false,value: 'SalePrice', width: '120px'},
          { text: 'สถานะ',align: 'center',sortable: false,value: 'Status', width: '60px' },
        ],
      },

      // ค้นหา
      search: {
        "name": null,
        "productCode": null,
        "barcode": null,
        "sku": null,
        "showProductList": [{"id": 0, "name": "--ทั้งหมด--"},{"id": 1, "name": "สินค้าที่มีสต๊อก"},{"id": 2, "name": "สินค้าที่ไม่มีสต๊อก"},{"id": 3, "name": "สินค้าที่ลบไปแล้ว"}],
        "showProduct": {"id": 0, "name": "--ทั้งหมด--"},
        "isActiveList": [{"id": 0, "name": "--ทั้งหมด--"},{"id": 1, "name": "เปิดขาย"},{"id": 2, "name": "ไม่เปิดขาย"}],
        "isActive": {"id": 1, "name": "เปิดขาย"},
        "showSetProduct": {"id": 2, "name": "ทั้งหมด"},
        "showSetProducList": [{"id": 2, "name": "--ทั้งหมด--"},{"id": 1, "name": "สินค้าจัดเซ็ท"},{"id": 0, "name": "สินค้าไม่จัดเซ็ท"}],
        orderbyName: 'id',
        isAscending: false,
      },
      warehouse_data:[],
      warehouse_selected:null,
      warehouseIDList:[],
      // Export Excel
      loadingExcel: 0,
      datasetExcel: [],
      headerExcel: [
        { label: "No", field: "no", width: 10},
        { label: "ชื่อ", field: "name", width: '50px'},
        { label: "ลักษณะ1", field: "prop1", width: 20},
        { label: "คำอธิบายลักษณะ1", field: "prop1Description", width: 20},
        { label: "ลักษณะ2", field: "prop2", width: 20},
        { label: "คำอธิบายลักษณะ2", field: "prop2Description", width: 20},
        { label: "ราคาขาย", field: "unitPrice", width: 20},
        { label: "รหัสสินค้า", field: "productCode", width: 15},
        { label: "รหัส CF", field: "cfCode", width: 15},
        { label: "SKU", field: "sku", width: 15},
        { label: "Barcode", field: "barcode", width: 15},
        { label: "คงเหลือ", field: "quantity", width: 10},
        { label: "Buffer", field: "quantityBuffer", width: 10},
        { label: "รอแพ็ค", field: "quantityWaiting", width: 10},
        { label: "รอนำเข้า", field: "quantityWaitImport", width: 10},
        { label: "พร้อมขาย", field: "quantityAvailable", width: 10},
        { label: "Active", field: "isActive", width: 10},
        { label: "คลัง", field: "warehouseName", width: 20},
        { label: "Brand", field: "brand", width: 20},
        { label: "จัดเซ็ท", field: "isProductSetText", width: 20},
      ],

      // tab
      tab: 0,
      tab_list: [{ "id": 0, "warehouseId": 0, "name": "--ทั้งหมด--", "total": 0 }],
      warehouseId: 0,

      // แสดงตาราง
      tableloading: false,
      total: 0,
      dataset: [],
      header_stock_show : [],
      IsUpdateStockMarketplace : false,
      IsHeaderDataNotTheSame : false,
      header: [
        { text: 'No',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' ,labal:"no_3" },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px',labal:"tb_image"},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0,  labal:"name"},
       
        { text: 'คงเหลือ',align: 'right',sortable: false,value: 'quantity', icon: 0, width: '90px' ,labal:"balance"},
        { text: 'Buffer',align: 'right',sortable: false,value: 'quantityBuffer', icon: 0, width: '90px' ,labal:"Buffer"},
        { text: 'รอแพ็ค',align: 'right',sortable: false,value: 'quantityWaiting', icon: 0, width: '90px' ,labal:"wati_pack"},
        { text: 'รอนำเข้า',align: 'right',sortable: false,value: 'quantityWaitImport', icon: 0, width: '90px' ,labal:"wati_import" },
        { text: 'พร้อมขาย',align: 'right',sortable: false,value: 'quantityAvailable', icon: 0, width: '100px' ,labal:"ready_sale" },
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0, width: '80px' ,labal:"sku" } ,
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode', icon: 0, width: '90px' ,labal:"barcode"  },
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0, width: '200px' ,labal:"type" },
        { text: 'ราคาขาย',align: 'center',sortable: false,value: 'unitPrice', icon: 0, width: '100px' ,labal:"selling_price" },
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', icon: 0, width: '100px' ,labal:"number_product"},
        { text: 'รหัส CF',align: 'center',sortable: false,value: 'cfCode', icon: 0, width: '90px' ,labal:"cfCode"},
        { text: 'คลัง',align: 'left',sortable: false,value: 'warehouseName', icon: 0, width: '70px' ,labal:"treasury"},
        { text: 'Brand',align: 'left',sortable: false,value: 'brand', icon: 0, width: '90px' ,labal:"Brand"},
        { text: 'costprice',align: 'left',sortable: false,value: 'staticCostprice', icon: 0, width: '100px' ,labal:"costprice"},
        { text: 'update_to_marketplace',align: 'center',sortable: false,value: 'isUpdateStockMarketplace_Item', width: '80px' ,labal:"update_to_marketplace" },
        { text: 'Active',align: 'center',sortable: false,value: 'isActive', icon: 0, width: '80px' ,labal:"isActive"} ,
        { text: 'ลบ',align: 'center',sortable: false,value: 'delete', icon: 0, width: '60px' ,labal:"delete"},
      ],
       // สำหรับ datatable move columns
      anIncreasingNumber: 1,

      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 10,
      offsets: 0,

      // สินค้าจัดเช็ต
     
      dialogDetail: false,
      productSetItem: {},
      headerDetail: [
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '70px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', width: '350px'},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', width: '100px'},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', width: '90px'},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '90px'},
      ],
      datasetDetail: [],

      // Shoppee import
      loadingStock : 0 ,
      // datasetStock_new : [],
      datasetStock_show : [],
      datasetStock_insert_false : [],
      insert_prosess_id: null,
      Insert_again : false,
      headers :[],
      formatDataTabel: true,
    }),
    computed: {
        showHeaders () {
          
      return this.headers.filter(s => this.headers.includes(s));
    }
    },
    watch: {
    },
    async created() {

          //เก็บภาษามาไว้ใน use_language
      this.use_language = await this.get_languages(localStorage.getItem("set_language"));

      document.title = this.$router.history.current.meta.title
      this.SeeCostPermisstion = JSON.parse(localStorage.getItem('permission'))['SeeCost']
      this.PermisstionManageStock = localStorage.getItem('230');

      
      await this.GetMarketplace()
      await this.GetWarehouse()
      await this.Load_Data(this.limits,this.offsets,this.search,1 )
      await this.Load_Count(this.limits,this.offsets,this.search)
      

      //ส่วนของการดึง viewtpye
      
      this.formatDataTabel = await this.ViewTypePage("my-stock");
      console.log(this.formatDataTabel);
      this.headers = [...this.header_stock_show];
      //ส่วนของการดึง viewtpye
      let remove = [ "barcode","prop1","productCode","cfCode","brand"]
      this.headers = [...this.header_stock_show] 
      if(!this.formatDataTabel)
      {
        for(var item in remove)
        {
            let index =  this.headers.findIndex(x => x.value === remove[item])
            if(index != -1)
            {
            this.headers.splice(index, 1);
            }
        } 
      }
 console.log(this.headers);
      this.page_loading = false
    },

   methods: {
      get_languages,
      async GetMarketplace()
      {
      
        var header_tmp = [
          { text: 'no_3',align: 'center',sortable: false,value: 'no', icon: 0, width: '50px' ,labal:"no_3" },
          { text: 'แก้ไข',align: 'center',sortable: false,value: 'editstock', icon: 0, width: '60px' ,labal:"แก้ไข"},
          { text: 'tb_image',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px' ,labal:"tb_image"},
          { text: 'name',align: 'left',sortable: false,value: 'name', icon: 0,width: '300px',  labal:"name"},
          { text: 'balance',align: 'right',sortable: false,value: 'quantity', icon: 0, width: '90px' ,labal:"balance"},
          { text: 'Buffer',align: 'right',sortable: false,value: 'quantityBuffer', icon: 0, width: '80px' ,labal:"Buffer"},
          { text: 'wati_pack',align: 'right',sortable: false,value: 'quantityWaiting', icon: 0, width: '90px' ,labal:"wati_pack"},
          { text: 'wati_import',align: 'right',sortable: false,value: 'quantityWaitImport', icon: 0, width: '90px' ,labal:"wati_import"},
          { text: 'ready_sale',align: 'right',sortable: false,value: 'quantityAvailable', icon: 0, width: '100px' ,labal:"ready_sale"},
          { text: 'sku',align: 'center',sortable: false,value: 'sku', icon: 0, width: '80px' ,labal:"sku" },
          { text: 'barcode',align: 'center',sortable: false,value: 'barcode', icon: 0, width: '90px' ,labal:"barcode" },
          { text: 'type',align: 'center',sortable: false,value: 'prop1', icon: 0, width: '200px' ,labal:"type" },
          { text: 'selling_price',align: 'center',sortable: false,value: 'unitPrice', icon: 0, width: '100px' ,labal:"selling_price" },
          { text: 'costprice',align: 'center',sortable: false,value: 'staticCostPrice', icon: 0, width: '100px' ,labal:"costprice" },
          { text: 'number_product',align: 'center',sortable: false,value: 'productCode', icon: 0, width: '100px' ,labal:"number_product" },
          { text: 'treasury',align: 'left',sortable: false,value: 'warehouseName', icon: 0, width: '110px' ,labal:"treasury" },
          { text: 'Brand',align: 'left',sortable: false,value: 'brand', icon: 0, width: '90px' ,labal:"Brand" },
         
          { text: 'update_to_marketplace',align: 'center',sortable: false,value: 'isUpdateStockMarketplace_Item', width: '80px' ,labal:"update_to_marketplace" },
          { text: 'active',align: 'center',sortable: false,value: 'isActive', icon: 0, width: '80px' ,labal:"active" } ]


       

          var data_h = await this.get_header_table(this.shop.isUpdateStockMarketplace.toString())
        
          this.IsUpdateStockMarketplace =this.shop.isUpdateStockMarketplace
          if (this.IsUpdateStockMarketplace)
          {
             if(data_h == '')
             {
              this.header_stock_show = [...header_tmp]
             }
             else
             {
              this.header_stock_show = [...JSON.parse(data_h.headersData)]
             }
          }
          else
          {
            if(data_h == '')
            {
              header_tmp.splice(header_tmp.map(e => e.text).indexOf('update_to_marketplace'),1);
              this.header_stock_show = [...header_tmp]
            }
            else
            {
              this.header_stock_show = [...JSON.parse(data_h.headersData)]
            }
          } 
        
      },
      async get_header_table (isUpdateStockMarketplace) {

       let response =  await axios.post(stockService_dotnet+'Stock/get-header-datatable-setting', {
          "shopID": localStorage.getItem('shop_id'),
          "description": isUpdateStockMarketplace,
          "urlPage": "my-stock"
        }, {headers: this.header_token})
        .then(res =>
        {
         return res.data;
        })
        .catch(error =>
        {
          return '';
        });

         return response;

      },

      async update_header_table () {

        this.loading = true
        var data_h = await this.get_header_table(this.IsUpdateStockMarketplace.toString())
        var id_tmp = 0
        if(data_h != ''){
          id_tmp = data_h.id
        }
       await axios.post(stockService_dotnet+'Stock/update-header-datatable-setting', {
          "id": id_tmp,
          "headersData": JSON.stringify(this.header_stock_show),
          "shopID": localStorage.getItem('shop_id'),
          "description": this.IsUpdateStockMarketplace.toString(),
          "urlPage": "my-stock"
        }, {headers: this.header_token})
        .then(res =>
        {
          this.GetMarketplace()
          this.IsHeaderDataNotTheSame = false
          this.loading = false
        })
        .catch(error =>
        {
          this.IsHeaderDataNotTheSame = false
          this.loading = false
        });





      },
      async changeFormat_DataTabel(){

        this.UpdateViewTypePage("my-stock",this.formatDataTabel); // ยิงอัพเดทสถานะบันทึกไว้

        let remove = [ "barcode","prop1","productCode","cfCode","brand"]


        this.headers = [...this.header_stock_show]

        if(this.formatDataTabel)
        {
          for(var item in remove)
          {
             let index =  this.headers.findIndex(x => x.value === remove[item])

             if(index != -1)
             {
              this.headers.splice(index, 1);
             }
          }

        }


        this.formatDataTabel = ! this.formatDataTabel;
      },

      async GetWarehouse() {
        let response = await axios.post(shopService_dotnet+'Shop/get-warehouse-list', {
          "shopId": localStorage.getItem('shop_id'),
        }, {headers: this.header_token})
        if (response.status ==200) {
          this.warehouseIDList=[]
          this.warehouse_data = [{"id": 0, "name": "--ทั้งหมด--"}];
          
          if (response.data.length>0){
            if(response.data.length==1){
              this.warehouse_data = [{"id": response.data[0].id, "name":response.data[0].name}];
              this.warehouseIDList.push( response.data[0].id);
            }else{
              for (var i in  response.data){
                this.warehouse_data.push({
                  "id": response.data[i].id,
                  "name": response.data[i].name,
                })
                this.warehouseIDList.push(response.data[i].id)
              }
            }
          }
          this.warehouse_selected = this.warehouse_data[0];
        }
      },
      async SearchStock(IsResetOffset){
        this.reSetSort()
        if(IsResetOffset){
          this.offsets=0;
          this.page=1;
        }
        this.Load_Data(this.limits,this.offsets,this.search,1 )
        this.Load_Count(this.limits,this.offsets,this.search )
        this.reSetIconHeader()
      },
      async ChangTab(tab,warehouseId){
        this.reSetSort()
        this.Load_Data(this.limits,0,this.search,1 )
        this.Load_Count(this.limits,0,this.search )
        this.reSetIconHeader()
      },
      reSetSort(){
        this.search.orderbyName = 'id'
        this.search.isAscending = false
      },
      reSetIconHeader(){
        for (var i in this.header){
          this.header[i].icon = 0
        }
      },
      async LimitChange(){
        await this.Load_Data(this.limits, 0, this.search, 1);
      },
      async ChangePage(){
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          this.Load_Data(this.limits,offsets,this.search,this.page)
          this.pageTemp = this.page
        }
      },
      async OrderByCollum(index){
        this.tableloading = true
        this.search.orderbyName = this.headers[index].value
        this.search.isAscending = this.headers[index].icon==0 ? false : (this.headers[index].icon==1 ? true : false)
        for (var i in this.headers) {
          if (!(parseInt(index) == parseInt(i))){
            this.headers[i].icon = 0
          }
        }
        this.headers[index].icon = this.headers[index].icon==0 ? 1 : (this.headers[index].icon==1 ? 2 : 1)
        await this.Load_Data(this.limits,this.offsets,this.search,this.page )
        this.tableloading = false
      },
      async Load_Data(limits,offsets,search,page) {
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp['brand'] =search.brand
        searchTemp["sku"] = search.sku 
        searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id
        searchTemp["isActive"] = search.isActive.id==0 ? null : search.isActive.id==1 ? true : false
        searchTemp["isProductSet"] = search.showSetProduct.id==2 ?  null : search.showSetProduct.id 
        var warehouseIDList = []
        var wl = this.search.warehouseList
        for (var k in wl) {
          warehouseIDList.push(wl[k].id)
        }
        searchTemp["orderbyName"] = search.orderbyName
        searchTemp["isAscending"] = search.isAscending
 
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-stockshop-list-by-warehouse', {
          "shopId": localStorage.getItem('shop_id'),
          "keyword": searchTemp,
          "isFromShop": true,
          "skip": offsets,
          "take": limits,
          "warehouseIDList":this.warehouse_selected.id==0 ? this.warehouseIDList : [this.warehouse_selected.id]
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status ==200) {
          this.loading = false 
          this.page = page
          this.pageTemp = page
          this.offsets = offsets 
          this.dataset = []
          this.dataset = response.data.resultData
        
        } else {
          this.loading = false
          Swal.fire({
            position: "top",
            icon: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      },
      async Load_Count(limits,offsets,search) {
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku 
        searchTemp['brand'] =search.brand
        searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id 
        searchTemp["isActive"] = search.isActive.id==0 ? null : search.isActive.id==1 ? true : false
        searchTemp["isProductSet"] = search.showSetProduct.id==2 ?  null : search.showSetProduct.id 
         
        let response = await axios.post(stockService_dotnet+'Stock/get-stockshop-count-by-warehouse', {
          "shopId": localStorage.getItem('shop_id'),
          "keyword": searchTemp,
          "isFromShop": true,
          "skip": offsets,
          "take": limits,
          "isNeedWarehouseCout": false,
          "warehouseIDList":this.warehouse_selected.id==0 ? this.warehouseIDList : [this.warehouse_selected.id]
        }, {headers: this.header_token})
        if (response.status ==200) {
          this.total = response.data.resultCount
           
          this.pageLength =  (Math.ceil(response.data.resultCount/this.limits))
          if (this.pageLength<=0){this.pageLength=1}
        } else {
          Swal.fire({
            position: "top",
            icon: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      },
      async DeleteStock(item){
        Swal.fire({
          position: "top",
          type: 'warning',
          title: 'ลบ ใช่หรือไม่',
          text: item.name +' '+ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : ""),
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = await axios.post(stockService_dotnet+'Stock/delete-stock', {
              "stockShopId": item.id,
            }, {headers: this.header_token})
            if (response.status==200 || response.status==204) {
              if (response.data.status=="success"){
                await this.SearchStock(false)
                this.loading = false
                Swal.fire({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'Success'
                })
              }else{
                this.loading = false
                Swal.fire({
                  position: "top",
                  type: "error",
                  text: response.data.text,
                })
              }
            }else{
              this.loading = false
              Swal.fire({
                position: "top",
                icon: "error",
                text: "ไม่สามารถทำรายการได้",
              })
            }
          }
        })
      },


      // สินค้าจัดเช็ต
      async Set_Load_Data(){
        var search = this.search
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["warehouseId"] = search.warehouse.id==0 ? null : search.warehouse.id
        searchTemp["warehouseIdList"] = []
        searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id
        searchTemp["isActive"] = search.isActive.id==0 ? null : search.isActive.id==1 ? true : false
        searchTemp["isProductSet"] = search.showSetProduct.id==2 ?  null : search.showSetProduct.id 

        for (var k in this.tab_list){
          if (parseInt(k)!=0){
            searchTemp.warehouseIdList.push(this.tab_list[k].warehouseId)
          }
        }
        if (searchTemp.warehouseId!=null){
          searchTemp.warehouseIdList = []
          searchTemp.warehouseIdList.push(searchTemp.warehouseId)
        }

        let response = await axios.post(stockService_dotnet+'Stock/get-productset-list-by-warehouse', {
          "shopId": localStorage.getItem('shop_id'),
          "keyword": searchTemp,
        }, {headers: this.header_token})
        if (response.status==200){
          this.set.dataset = []
          this.set.datasetTemp = []
          this.set.dataset = response.data
          this.set.datasetTemp = response.data
          this.set.total = response.data.length
          for (var o in this.set.dataset){
            this.set.dataset[o].no = this.set.dataset.length - parseInt(o)
          }
        }
      },
      async ProductSetOfDetail(item){
        this.loading = true
        this.productSetItem = item
        await axios.all([
          axios.post(stockService_dotnet+'Stock/get-productset-vstockmaster-list', {
            'productMasterId': item.productMasterId,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productset-list', {
            'productMasterId': item.productMasterId,
          }, {headers: this.header_token}),
        ])
        .then(axios.spread((productsetVStockMaster, productset) => {
          if (productsetVStockMaster.status==200 && productset.status==200){
            productsetVStockMaster = productsetVStockMaster.data
            productset = productset.data
            for (var k in productsetVStockMaster){
              for (var j in productset){
                if (productsetVStockMaster[k]["productMasterId"]==productset[j]["productMasterId"] && productsetVStockMaster[k]["id"]==productset[j]["productMasterItemId"]){
                  productsetVStockMaster[k]["quantity"] = parseInt(productset[j]["quantity"])
                }
              }
            }
            this.datasetDetail = productsetVStockMaster
            this.loading = false
            this.dialogDetail = true
          }else{
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้",
            })
          }
        }));
      },
      async DeleteProductSet(item){
        Swal.fire({
          position: "top",
          type: 'warning',
          title: 'ลบ ใช่หรือไม่',
          text: item.name +' '+ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : ""),
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = await axios.post(stockService_dotnet+'Stock/delete-productset', {
              "productMasterId": item.productMasterId,
            }, {headers: this.header_token})
            if (response.status==200 || response.status==204) {
              this.loading = false
              if (response.data.status=="success"){
                this.set.dataset.splice(this.set.dataset.indexOf(item),1)
                Swal.fire({
                  toast: true,
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'top',
                  type: 'success',
                  title: 'Success'
                })
              }else{
                Swal.fire({
                  position: "top",
                  type: "error",
                  text: response.data.text,
                })
              }

            }else{
              this.loading = false
              Swal.fire({
                position: "top",
                icon: "error",
                text: "ไม่สามารถทำรายการได้",
              })
            }
          }
        })
      },
      UpdateStock(){
        var item = this.productSetItem
        var data = {
          "productMasterId": null,
          "productMasterItemId": null,
          "isProductSet": 0,
          "part_return": "/my-stock",
        }
        data.productMasterId = item.productMasterId
        data.productMasterItemId = item.productMasterItemId
        data.isProductSet = item.isProductSet ? 1 : 0
        localStorage.setItem('data', JSON.stringify(data))
        window.open("/my-product/update")
       
      },

 
      async aget_data_stock_by_ofset (limits, offsets) {
        let res1 = await axios.post(ecomService_dotnet+'Shopee/get-items-list', {
          "offset": offsets,
          "limit":limits,
          "shopShopeeID": parseInt(this.shopee.shop.id)
        }, {headers: this.header_token})
        var json = JSON.parse(res1.data.item_detail);
        var json_show = JSON.parse(res1.data.item_show);
        var data_res =[json.items, json_show, res1.data.more]
        return data_res
      },
      async UpdateProduct(){
        this.loading = true
        this.Insert_again = false
        this.insert_prosess_id = null
        this.datasetStock_insert_false = []
        for (var i = 0; i < this.datasetStock_show.length; i++) {

          try {
            this.insert_prosess_id = this.datasetStock_show[i].ID


            let res1 = await axios.post(ecomService_dotnet+'Shopee/insert-stock-to-packhai',
              {
                "branchID": localStorage.getItem("branch_id"),
                "shopID": localStorage.getItem("shop_id"),
                "warehouseID": this.shopee.warehouse.id,
                "isWarehouse":  this.shopee.warehouse.name == 'คลังส่วนตัว' ? false : true ,
                "dataInsert": {
                  "id": this.datasetStock_show[i].ID,
                  "photoLink": this.datasetStock_show[i].PhotoLink,
                  "name": this.datasetStock_show[i].Name,
                  "quantity": this.datasetStock_show[i].Quantity,
                  "salePrice": this.datasetStock_show[i].SalePrice,
                  "prop1_description": this.datasetStock_show[i].Prop1_description,
                  "prop2_description": this.datasetStock_show[i].Prop2_description,
                  "shopShopeeID": this.datasetStock_show[i].ShopShopeeID,
                  "branchID": this.datasetStock_show[i].BranchID,
                  "shopID": this.datasetStock_show[i].ShopID,
                  "productCode": this.datasetStock_show[i].ProductCode,
                  "shopeeItemID": this.datasetStock_show[i].ShopeeItemID,
                  "description": this.datasetStock_show[i].Description,
                  "prop1": this.datasetStock_show[i].Prop1,
                  "prop2": this.datasetStock_show[i].Prop2,
                  "unitPrice": this.datasetStock_show[i].UnitPrice,
                  "costPrice": this.datasetStock_show[i].CostPrice,
                  "weight": this.datasetStock_show[i].Weight,
                  "cubic1": this.datasetStock_show[i].cubic1,
                  "cubic2": this.datasetStock_show[i].cubic2,
                  "cubic3": this.datasetStock_show[i].Cubic3,
                  "isVisibleToSaleman": this.datasetStock_show[i].IsVisibleToSaleman,
                  "isDelete": this.datasetStock_show[i].IsDelete,
                  "isActive": this.datasetStock_show[i].IsActive,
                  "alertQuantity": this.datasetStock_show[i].AlertQuantity,
                  "sku": this.datasetStock_show[i].SKU,
                  "referenceNo": this.datasetStock_show[i].ReferenceNo,
                  "shopeeVariationID": this.datasetStock_show[i].ShopeeVariationID,
                  "shopOrigin": this.datasetStock_show[i].ShopOrigin,
                  "status_Insert": this.datasetStock_show[i].Status_Insert
                }

              }, {headers: this.header_token})

            if(res1.data == "ดำเนินการเรียบร้อย"){
              this.datasetStock_show[i].Status = true ;
            } else {
              this.datasetStock_show[i].Status = false ;
              this.datasetStock_insert_false.push(this.datasetStock_show[i])
            }

            if(this.insert_prosess_id == this.datasetStock_show.length){
              this.insert_prosess_id = null
            }

          } catch (e) {
            this.datasetStock_insert_false.push(this.datasetStock_show[i])
            this.datasetStock_show[i].Status = false ;
          }
        }

        if(this.datasetStock_insert_false.length > 0){
          for (var i2 = 0; i2 < this.datasetStock_insert_false.length; i2++) {
            this.datasetStock_insert_false[i2].ID =  i2+1 ;
            // this.datasetStock_insert_false[i2].Status = null ;
          }

          this.datasetStock_show = [...this.datasetStock_insert_false]
          this.datasetStock_insert_false = []

          this.loading = false

          this.Insert_again = true
        } else {
          this.loading = false
          this.Insert_again = false
          this.datasetStock_show = []
          this.shopee.dialog = false

          Swal.fire({
            position: "top",
            type: "success",
            text: "ดำเนินการเรียบร้อย !!",
            showConfirmButton: false,
            timer: 1500
          })
        }

      },


      // เรียกใช้
      AddProductSet(item){
        var data = {
          "productMasterId": null,
          "productMasterItemId": null,
          "isProductSet": 1,
        }
        localStorage.setItem('data', JSON.stringify(data))
        this.$router.push("/my-product/update")
      },
      async ConfirmUpdateIsActive(index,item){
        Swal.fire({
          position: "top",
          type: 'warning',
          title: 'เปลี่ยนสถานะ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {

            item.isActive = !item.isActive
            this.UpdateIsActive(index,item)

          }
        })
      },
      async UpdateIsActive(index,item){
        let response = await axios.post(stockService_dotnet+'Stock/update-isactive', {
          "stockShopId": item.id,
          "isActive": item.isActive,
        }, {headers: this.header_token})
        if (response.status==200) {
          // if (this.search.isActive.id!=0 && (this.search.isActive.id!=item.isActive)){
          //   this.dataset.splice(index,1)
          //   this.total = this.total-1
          //   this.tab_list[0]["total"] = this.tab_list[0]["total"]-1
          //   for (var i in this.tab_list){
          //     if (this.tab_list[i]["warehouseId"] == item.warehouseId){
          //       this.tab_list[i]["total"] = this.tab_list[i]["total"]-1
          //       break
          //     }
          //   }
          // }
        }else{
          item.isActive = !item.isActive
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      },
      async ConfirmUpdateIsActiveProductSet(index,item){
        Swal.fire({
          position: "top",
          type: 'warning',
          title: 'เปลี่ยนสถานะ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {

            item.isActive = !item.isActive
            this.UpdateIsActiveProductSet(index,item)

          }
        })
      },
      
      async UpdateIsActiveProductSet(index,item){
        let response = await axios.post(stockService_dotnet+'Stock/update-isactive-productset', {
          "productMasterId": item.productMasterId,
          "isActive": item.isActive,
        }, {headers: this.header_token})
        if (response.status==200) {
          // if (this.search.isActive.id!=0 && (this.search.isActive.id!=item.isActive)){
          //   this.set.dataset.splice(index,1)
          //   this.set.total = this.set.total-1
          // }
        }else{
          item.isActive = !item.isActive
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      },
      async UpdateMarketplace_Item(index,item){
        let response = await axios.post(stockService_dotnet+'Stock/update-marketplace-item', {
          "stockShopID": item.id,
          "isUpdateStockMarketplace": item.isUpdateStockMarketplace_Item,
        }, {headers: this.header_token})
        if (response.status!=200) {
          item.isUpdateStockMarketplace_Item = !item.isUpdateStockMarketplace_Item
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      },
      async ExportExcel(search){
       
        this.datasetExcel = []
        
        Swal.fire({
          position: "top",
          type: 'warning',
          text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async(result) => {
          if (result.value) {
            var searchTemp = {}
            searchTemp["name"] = search.name
            searchTemp["productCode"] = search.productCode
            searchTemp["barcode"] = search.barcode
            searchTemp["sku"] = search.sku
           
            searchTemp["showProduct"] = search.showProduct.id==0 ? null : search.showProduct.id
            searchTemp["isActive"] = search.isActive.id==0 ? null : search.isActive.id==1 ? true : false
            searchTemp["isProductSet"] = search.showSetProduct.id==2 ?  null : search.showSetProduct.id 
            
            searchTemp["orderbyName"] = search.orderbyName
            searchTemp["isAscending"] = search.isAscending

            // for (var k in this.tab_list){
            //   if (parseInt(k)!=0){
            //     searchTemp.warehouseIdList.push(this.tab_list[k].warehouseId)
            //   }
            // }

            var limits = 50
            var offsets = 0
            this.loadingExcel = 1 
            var round = parseInt( this.total / limits)

            if ( limits * round < this.total ){
              round=round+1
            }

            var current = 0
            for (var i =1; i <=round; i++) {
              var data = await this.GetExportExcelProduct(limits,offsets,searchTemp)
             
              for (var j in data){
                
                if(data[j]['isProductSet']){
                  data[j]['isProductSetText']  = "จัดเซ็ท"
                }
                this.datasetExcel.push(data[j])
                current += 1
              }
              this.loadingExcel = parseInt(parseFloat( i) / parseFloat(round) * parseFloat(100))
              offsets += limits
            }

            for (var d in this.datasetExcel){
              this.datasetExcel[d]["no"] = this.datasetExcel.length - parseInt(d)
            
            }

            this.loadingExcel = this.datasetExcel.length==0 ? 0 : 100
            document.getElementById("GetExcel").click();
            this.loadingExcel = 0
          }
        })
        this.loading = false;
       
      },
      async GetExportExcelProduct(limits,offsets,searchTemp){
        try {
          let stockList = await axios.post(stockService_dotnet+'Stock/get-stockshop-list-by-warehouse', {
            "shopId": localStorage.getItem('shop_id'),
            "keyword": searchTemp,
            "isFromShop": true,
            "skip": offsets,
            "take": limits,
            "warehouseIDList":this.warehouse_selected.id==0 ? this.warehouseIDList : [this.warehouse_selected.id]
          }, {headers: this.header_token})
          if (stockList.status==200){
            return stockList.data.resultData
          }else{
            return []
          }
        }

        catch (e) {
          console.log('error');
        }
      },
      OpenDialogEditStock(item){
        this.editstock.dialog = true
        this.editstock.stockID = item.id
        this.editstock.name = item.name
        this.editstock.SKU = item.sku
        this.editstock.productCode = item.productCode
        this.editstock.cfCode = item.cfCode
        this.editstock.barcode = item.barcode
        this.editstock.price = item.salePrice
        this.editstock.staticCostPrice = item.staticCostPrice
        this.editstock.stockquantity = item.quantity
        this.editstock.ispackhai = item.isPackhaiWarehouse
        this.editstock.buffer = item.quantityBuffer
        this.editstock.prop1Description = item.prop1Description
        this.editstock.prop2Description = item.prop2Description
      },
    async handleUpdateStock(){
        // if (this.$refs.editStockForm.validate()) {
      this.loading = true
      let response = await axios.post(
          stockService_dotnet+"Stock/update-product-and-stock",
        {
          shopID: localStorage.getItem("shop_id"),
          barcode: this.editstock.barcode,
          productCode: this.editstock.productCode,
          quantity:this.editstock.stockquantity,
          unitPrice:this.editstock.price,
          salePrice:this.editstock.price,
          sku:this.editstock.SKU,
          staticCostPrice:this.editstock.staticCostPrice,
          staffShopId:localStorage.getItem("staff_id"),
          buffer:this.editstock.buffer === null || this.editstock.buffer === "" ? 0 : this.editstock.buffer,
          productMasterName:this.editstock.name,
          stockId:this.editstock.stockID,
          prop1Description : this.editstock.prop1Description,
          prop2Description : this.editstock.prop2Description,
        },
        { headers: this.header_token }
      );
      if(response.data.status === "success"){
          this.loading = false
          for (var item of this.dataset)
          {
            if(item.id == this.editstock.stockID)
            {
              item.buffer = this.editstock.buffer === null || this.editstock.buffer === "" ? 0 : this.editstock.buffer;
              item.sku = this.editstock.SKU;
              item.unitPrice = this.editstock.price;
              item.salePrice = this.editstock.salePrice;
              item.barcode = this.editstock.barcode;
              item.quantity = this.editstock.quantity;
              item.staticCostPrice=this.editstock.staticCostPrice;
              item.name = this.editstock.name;
              item.productCode = this.editstock.productCode;
              item.prop1Description = this.editstock.prop1Description;
              item.prop2Description= this.editstock.prop2Description ;
            }
            this.editstock.dialog = false
            Swal.fire({
                    toast: true,
                    timer: 2000,
                    showConfirmButton: false,
                    position: 'top',
                    type: 'success',
                    title: 'แก้ไขสำเร็จ'
          
          })
        }
      } else{
              this.loading = false
              Swal.fire({
                  toast: true,
                  timer: 3000,
                  position: 'top',
                  type: 'error',
                  title: response.data.message.th,
                  showConfirmButton: false,
                  })
        }
      },
      navigateExcel(){
        this.$router.push("/update/excel");
      },
      gotoStock_InFo(item){
        // if(!item.isProductSet){
        //   window.open("stock-info?id="+item.id);
        // }else{
        //   this.ProductSetOfDetail(item);
        // }
        window.open("stock-info?id="+item.id);
          
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      Letgo(text,item,isProductSet){
        if (text==="รายละเอียด" && !isProductSet){
          this.gotoStock_InFo(item)
        }else if (text==="ลบ" && !isProductSet){
          this.DeleteStock(item)
        }else if (text==="รายละเอียด" && isProductSet){
          this.ProductSetOfDetail(item)
        }else if (text==="ลบ" && isProductSet){
          this.DeleteProductSet(item)
        }
      },

      // datatble move columns
      sortTheHeadersAndUpdateTheKey(evt) {

        const headersTmp = this.header_stock_show;
        const oldIndex = evt.oldIndex;
        const newIndex = evt.newIndex;
        if (newIndex >= headersTmp.length) {
          let k = newIndex - headersTmp.length + 1;
          while (k--) {
            headersTmp.push(undefined);
          }
        }
        headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
        this.table = headersTmp;
        this.anIncreasingNumber += 1;
        this.IsHeaderDataNotTheSame = true
         this.headers = [...this.header_stock_show];
      },


      format_price,
      formatMoney,
      formatDate_thai,
      isNumberWithDot,
      isNumberWNoDot,
      DateNowNotNull,
      Trim_value,
      Trim_rules,
      ViewTypePage,
      UpdateViewTypePage
    },

    directives: {
      'sortable-table': {
        inserted: (el, binding) => {
          el.querySelectorAll('th').forEach((draggableEl) => {
            // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
            watchClass(draggableEl, 'sortHandle');
            draggableEl.classList.add('sortHandle');
          });
          Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {});
        },
      },
    },
  }
</script>
